import React, { Component } from 'react'
import { css } from '@emotion/core';

import './startup.css';

import Layout from '../components/BSLayout';
import Img from 'gatsby-image';
import { graphql } from 'gatsby'

import DesktopNavbar from '../components/DesktopNavbar';
import MobileNavbar from '../components/MobileNavbar';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

import Stage from '../components/stage';

const PrimaryCTA = () => (
  <div className="block-xs-middle pb-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h1 className="block-titleData frequency">Thank you!</h1>
          <p className="lead mb-4">We'll get back to you ASAP.</p>
        </div>
      </div>
    </div>
  </div>
)

const HeadData = () => (
  <Helmet>
    <title>Thanks for contacting us! -Castellan</title>
    <meta name="robots" content="noindex" />
  </Helmet>
)


const cssNavbar = css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    font-weight: 500;
    letter-spacing: .05em;
`
const cssCTABackground = css`
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #000;
`



class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  navToggle = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen});
  }

  render() {
    const { data} = this.props;
    const { menuOpen } = this.state;
    return (
        <Layout>
          <HeadData />
          <div>
            <MobileNavbar />
            <Stage isOpen={menuOpen}>
              <div className="block block-inverse block-fill-height app-header" >
                <div css={cssNavbar} >
                <DesktopNavbar onNavToggle={this.navToggle} transparent={true} />
                </div>
                <Img 
                fluid={data.primaryBackground.childImageSharp.fluid} 
        css={cssCTABackground}
                />
                <PrimaryCTA />
              </div>
              <Footer />
            </Stage>
          </div>
        </Layout>
    );
};
}

export const query = graphql`
    query {
      primaryBackground: file(relativePath: { eq: "thank_you.jpg" }) {
        childImageSharp {
          fluid(maxWidth:3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`

export default Page